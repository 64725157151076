import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, Typography } from '@material-ui/core';

import { StaticImage } from "gatsby-plugin-image"

import Download from "../components/download"

import { Container, Row, Col, Card } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Facilities = () => (
  <Layout>
    <Seo title="Facilities Offered by the College" description="Facilities Offered by the College" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row>
        <Col xs={12} lg={8} className="bg-white">
        <div className="p-3 list-icon mt-4">
           <Typography variant="h4" gutterBottom style={{ color:'#453260' }}>HOSTEL FACILITIES</Typography>
           <div className="text-center pt-3 pb-3">
           <StaticImage
      src="../images/facilities.jpg"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="HOSTEL FACILITIES"
      class="p-2 shadow bg-light"
    />
        </div>
          <Typography variant="body2" paragraph>
          Our College provides excellent Hostel facilities. Spacious rooms with attached bath are available for both boys and girls. Security and Safety of the students is of prime importance.
          </Typography>
          <Typography variant="h4" gutterBottom className="pt-3" style={{ color:'#453260', textTransform:'uppercase' }}>Internet and Wi-Fi facility</Typography>
          <div className="text-center pt-3 pb-3">
           <StaticImage
      src="../images/wifi.png"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="WIFI"
      class="border p-2 shadow bg-light"
    />
        </div>
         <Typography variant="body2" paragraph>
          College is having a Internet connection, locally networked and connected through Wi-Fi also to the central server are available for students for their project work and preparing seminar presentations. It also access to e-books, study materials, previous question papers daily circulars, etc. through the college Local Area Network. Internet facilities in the well-equipped internet lab, providing high speed of connectivity the student can surf the net together unlimited information
          </Typography>
           
        </div>
        </Col>
        <Col xs={12} lg={4} className="bg-white">
        <Card className="shadow mb-2 bg-light mt-5">
  <Card.Body>
<Download/>
</Card.Body>
</Card>
 
</Col>

    </Row>
</Container>
</Box>

</ThemeProvider>
  </Layout>
)

export default Facilities
